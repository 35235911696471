import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/Contract`
const PROP_URL_CREATE = `${API_URL}/Custom/AddContract`
const PROP_URL_DELETE = `${API_URL}/Custom/DeleteContract`
const PROP_URL_GETEXPIREDCONTRACTS = `${API_URL}/Custom/GetExpiredContracts`
const PROP_URL_GETEXPIRATIONAPPROACHEDCONTRACTS = `${API_URL}/Custom/GetExpirationApproachedContracts`
const PROP_URL_GETBYID = `${API_URL}/Custom/GetContractById`
const PROP_URL_UPDATE = `${API_URL}/Custom/UpdateContract`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getContract = (
  query: string,
  select?: string,
  modelstoinclude?: string
): Promise<QueryResponse> => {
  const qis = query.split('&')
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_id')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('String')) {
        const aq = capitalizeFirstLetter(
          q.replace('String', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  if (select != undefined && select != '') {
    sti = '&select=' + select
  }

  return axios
    .get(`${PROP_URL}/getAll/${newqstring}?${query}${mti}${sti}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listContract = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

// const getContractById = (id: ID): Promise<Model | undefined> => {
//   return axios
//     .get(`${PROP_URL}/${id}`)
//     .then((response: AxiosResponse<Response<Model>>) => response.data)
//     .then((response: Response<Model>) => response as any)
// }

const getContractById = (id: ID): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL_GETBYID}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const createContract = (prop: Model): Promise<Model | undefined> => {
  return axios
    .put(PROP_URL_CREATE, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

// const updateContract = (prop: Model): Promise<Model | undefined> => {
//   return axios
//     .post(`${PROP_URL}/${prop.id}`, prop)
//     .then((response: AxiosResponse<Response<Model>>) => response.data)
//     .then((response: Response<Model>) => response.data)
// }

const updateContract = (prop: Model): Promise<Model | undefined> => {
  return axios
    .post(`${PROP_URL_UPDATE}`, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteContract = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL_DELETE}/${propId}`).then(() => {})
}

const deleteSelectedContract = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getExpiredContracts = async (): Promise<any> => {
  return await axios.get(`${PROP_URL_GETEXPIREDCONTRACTS}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const getExpirationApproachedContracts = async (): Promise<any> => {
  return await axios
    .get(`${PROP_URL_GETEXPIRATIONAPPROACHEDCONTRACTS}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

export {
  getContract,
  deleteContract,
  deleteSelectedContract,
  getContractById,
  createContract,
  updateContract,
  listContract,
  getExpiredContracts,
  getExpirationApproachedContracts,
}
