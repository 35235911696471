import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import CustomSelect from '../../modules/custom-select/CustomSelect'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../_metronic/helpers'
// import {useQueryRequest} from '../../core/QueryRequestProvider'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getCustomerContractsTotalAmount} from '../customer/list/core/_requests'
import {ChartsWidgetGeneralBar2 as ChartsWidgetGeneralBar} from '../../../_metronic/partials/widgets/charts/ChartsWidgetGeneralBar2'
import moment from 'moment'

type Props = {
  className: string
}
const ContractOfferIncomeStats: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  //   const {updateState} = useQueryRequest()
  //   const {isLoading} = useQueryResponse()

  const [customers, setcustomers] = React.useState([])

  useEffect(() => {
    getCustomerContractsTotalAmount().then((res: any) => {
      console.log(res)
      setcustomers(
        res.map((a: any) => {
          return {value: a?.contractId[0]?.id, label: a?.name}
        }) || []
      )
    })
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div
        className={`card ${className}`}
        style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', margin: '10px'}}
      >
        {customers.map((a: any) => a.value).length > 1 && (
          <div className='mb-10 col-12'>
            <ChartsWidgetGeneralBar
              className=''
              title={intl.formatMessage({id: 'CustomerContract.STATS'})}
              series={customers.map((a: any) => a.value)}
              labels={customers.map((a: any) => a.label)}
              name='Toplam Tutar'
            />
          </div>
        )}
      </div>
    </>
  )
}

export {ContractOfferIncomeStats}
