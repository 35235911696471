import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import CustomSelect from '../../modules/custom-select/CustomSelect'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../_metronic/helpers'
import {getCustomerContractStatusTotalAmount} from '../customer/list/core/_requests'
import {ChartsWidgetGeneral2 as ChartsWidgetGeneral} from '../../../_metronic/partials/widgets/charts/ChartsWidgetGeneral2'
import moment from 'moment'

type Props = {
  className: string
}

const ContractStatusIncomeStats: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  //   const {updateState} = useQueryRequest()
  //   const {isLoading} = useQueryResponse()

  const [contractstatuss, setcontractstatuss] = React.useState([])

  useEffect(() => {
    getCustomerContractStatusTotalAmount().then((res: any) => {
      setcontractstatuss(
        res.map((a: any) => {
          return {value: a?.contractId[0]?.id, label: a?.name}
        }) || []
      )
    })

    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div
        className={`card ${className}`}
        style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', margin: '10px'}}
      >
        {contractstatuss.map((a: any) => a.value).length > 1 && (
          <div className='mb-10 col-12'>
            <ChartsWidgetGeneral
              className=''
              title={intl.formatMessage({id: 'ContractStatus.STATS'})}
              series={contractstatuss.map((a: any) => a.value)}
              labels={contractstatuss.map((a: any) => a.label)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export {ContractStatusIncomeStats}
