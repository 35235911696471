import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ChartsWidgetGeneral, ChartsWidgetGeneralBar} from '../../../_metronic/partials/widgets'
import {Vertical} from '../../modules/wizards/components/Vertical'
import {getApplicationRole as getApplicationRole} from '../applicationrole/list/core/_requests'
import './Hide.css'
import {ExpiredPayments} from './ExpiredPayments'
import {ApproachedPayments} from './ApproachedPayments'
import {ExpiredContracts} from './ExpiredContracts'
import {ExpirationApproachedContracts} from './ExpirationApproachedContracts'
import {DashboardNotes} from './DashboardNotes'
import {ContractOfferIncomeStats} from './ContractOfferIncomeStats'
import {ContractStatusIncomeStats} from './ContractStatusIncomeStats'

const DashboardPage = () => {
  const intl = useIntl()
  const [organizations, setorganizations] = React.useState([])
  const [organizations2, setorganizations2] = React.useState<Array<number>>([])
  const [organizations3, setorganizations3] = React.useState<Array<string>>([])
  const [surveytypes, setsurveytypes] = React.useState([])
  const [semesters, setsemesters] = React.useState([])
  const [applicationroles, setapplicationroles] = React.useState([])
  //document?.getElementById("kt_header")?.classList.add("hide");

  useEffect(() => {
    getApplicationRole(
      'items_per_page=99999',
      'Id,Name,ApplicationUserRole*ApplicationRoleId',
      'ApplicationUserRole'
    ).then((res: any) => {
      setapplicationroles(
        res.data.map((a: any) => {
          return {value: a?.applicationUserRoleApplicationRoleId.length, label: a?.name}
        }) || []
      )
    })
  }, [])

  useEffect(() => {
    setorganizations2(organizations.map((a: any) => a.value))
    setorganizations3(organizations.map((a: any) => a.label))
  }, [organizations])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Dashboard'})}</PageTitle>
      <div className='row g-5 g-xl-12'>
        <div className='col-12'>
          <DashboardNotes className='card-xxl-stretch' />
        </div>
        <div className='row gy-5 g-xl-8'>
          <div className='col-6'>
            <ExpiredPayments className='card-xxl-stretch' />
          </div>
          <div className='col-6'>
            <ApproachedPayments className='card-xxl-stretch' />
          </div>
        </div>

        <div className='row gy-5 g-xl-8'>
          <div className='col-6'>
            <ExpiredContracts className='card-xxl-stretch' />
          </div>
          <div className='col-6'>
            <ExpirationApproachedContracts className='card-xxl-stretch' />
          </div>
        </div>
        <div className='col-12'>
          <ContractOfferIncomeStats className='card-xxl-stretch' />
        </div>
        <div className='offset-3 col-6'>
          <ContractStatusIncomeStats className='card-xxl-stretch' />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div className='card-xl-stretch mb-5 mb-xl-10'>
        <Vertical />
      </div>
    </EnableSidebar>
  )
}

export {DashboardPage}
