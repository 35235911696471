import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPage} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = (menus: Array<string>) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ModelListPage = lazy(() => import('../pages/modellist/Page'))
  const MethodListPage = lazy(() => import('../pages/methodlist/Page'))
  const RoleMethodListPage = lazy(() => import('../pages/rolemethodlist/Page'))
  const RoleModelListPage = lazy(() => import('../pages/rolemodellist/Page'))
  const MenuListPage = lazy(() => import('../pages/menulist/Page'))
  const MenuRolePage = lazy(() => import('../pages/menurole/Page'))
  const UserActionPage = lazy(() => import('../pages/useraction/Page'))
  const CulturePage = lazy(() => import('../pages/culture/Page'))
  const TranslationPage = lazy(() => import('../pages/translation/Page'))
  const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
  const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
  const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
  const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
  const ApplicationRoleClaimPage = lazy(() => import('../pages/applicationroleclaim/Page'))
  const TenantPage = lazy(() => import('../pages/tenant/Page'))
  const SettingsPage = lazy(() => import('../pages/settings/Page'))
  const CustomerPage = lazy(() => import('../pages/customer/Page'))
  const CustomerContactPage = lazy(() => import('../pages/customercontact/Page'))
  const CustomerTypePage = lazy(() => import('../pages/customertype/Page'))
  const CustomerNotePage = lazy(() => import('../pages/customernote/Page'))
  const ContractPage = lazy(() => import('../pages/contract/Page'))
  const OfferPage = lazy(() => import('../pages/offer/Page'))
  const ContractFilePage = lazy(() => import('../pages/contractfile/Page'))
  const ContractStatusPage = lazy(() => import('../pages/contractstatus/Page'))
  const OfferStatusPage = lazy(() => import('../pages/offerstatus/Page'))
  const PaymentPlanPage = lazy(() => import('../pages/paymentplan/Page'))
  const InvoicePage = lazy(() => import('../pages/invoice/Page'))
  const ProductPage = lazy(() => import('../pages/product/Page'))
  const ContractProductPage = lazy(() => import('../pages/contractproduct/Page'))
  const OfferProductPage = lazy(() => import('../pages/offerproduct/Page'))
  const ISODocumentPage = lazy(() => import('../pages/isodocument/Page'))
  const ISODocumentTypePage = lazy(() => import('../pages/isodocumenttype/Page'))

  return (
    <Routes>
      <Route element={<MasterLayout {...(menus || [])} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />

        <Route path='dashboard' element={<DashboardPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='modellist/*'
          element={
            <SuspensedView>
              <ModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='methodlist/*'
          element={
            <SuspensedView>
              <MethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemethodlist/*'
          element={
            <SuspensedView>
              <RoleMethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemodellist/*'
          element={
            <SuspensedView>
              <RoleModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menulist/*'
          element={
            <SuspensedView>
              <MenuListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menurole/*'
          element={
            <SuspensedView>
              <MenuRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='useraction/*'
          element={
            <SuspensedView>
              <UserActionPage />
            </SuspensedView>
          }
        />
        <Route
          path='culture/*'
          element={
            <SuspensedView>
              <CulturePage />
            </SuspensedView>
          }
        />
        <Route
          path='translation/*'
          element={
            <SuspensedView>
              <TranslationPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuser/*'
          element={
            <SuspensedView>
              <ApplicationUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationrole/*'
          element={
            <SuspensedView>
              <ApplicationRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserrole/*'
          element={
            <SuspensedView>
              <ApplicationUserRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserclaim/*'
          element={
            <SuspensedView>
              <ApplicationUserClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationroleclaim/*'
          element={
            <SuspensedView>
              <ApplicationRoleClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenant/*'
          element={
            <SuspensedView>
              <TenantPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='customercontact/*'
          element={
            <SuspensedView>
              <CustomerContactPage />
            </SuspensedView>
          }
        />
        <Route
          path='customertype/*'
          element={
            <SuspensedView>
              <CustomerTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='customernote/*'
          element={
            <SuspensedView>
              <CustomerNotePage />
            </SuspensedView>
          }
        />
        <Route
          path='contract/*'
          element={
            <SuspensedView>
              <ContractPage />
            </SuspensedView>
          }
        />
        <Route
          path='offer/*'
          element={
            <SuspensedView>
              <OfferPage />
            </SuspensedView>
          }
        />
        <Route
          path='contractfile/*'
          element={
            <SuspensedView>
              <ContractFilePage />
            </SuspensedView>
          }
        />
        <Route
          path='contractstatus/*'
          element={
            <SuspensedView>
              <ContractStatusPage />
            </SuspensedView>
          }
        />
        <Route
          path='offerstatus/*'
          element={
            <SuspensedView>
              <OfferStatusPage />
            </SuspensedView>
          }
        />
        <Route
          path='paymentplan/*'
          element={
            <SuspensedView>
              <PaymentPlanPage />
            </SuspensedView>
          }
        />
        <Route
          path='invoice/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='contractproduct/*'
          element={
            <SuspensedView>
              <ContractProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='offerproduct/*'
          element={
            <SuspensedView>
              <OfferProductPage />
            </SuspensedView>
          }
        />

        <Route
          path='isodocument/*'
          element={
            <SuspensedView>
              <ISODocumentPage />
            </SuspensedView>
          }
        />

        <Route
          path='isodocumenttype/*'
          element={
            <SuspensedView>
              <ISODocumentTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
