/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain(menus: Array<string>) {
  const intl = useIntl()

  const uniqueTags: string[] = []
  Object.keys(menus).map((fullstr: any, key) => {
    if (
      uniqueTags.indexOf(menus[fullstr].split('.')[0].split('_')[1]) === -1 &&
      menus[fullstr].split('.')[0].split('_')[0] != 'main'
    ) {
      uniqueTags.push(menus[fullstr].split('.')[0].split('_')[1])
    }
  })

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {Object.keys(menus).map((fullstr: any, key) => {
        return (
          menus[fullstr].split('.')[0].split('_')[0] == 'main' && (
            <AsideMenuItem
              key={key}
              to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({id: 'MENU.' + menus[fullstr].split('.')[1].split('_')[1]})}
              fontIcon='bi-app-indicator'
            />
          )
        )
      })}

      {uniqueTags.map((tag: any, key) => (
        <AsideMenuItemWithSub
          key={key}
          to='/library'
          title={intl.formatMessage({id: 'MENU.' + tag})}
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/arrows/arr001.svg'
        >
          {Object.keys(menus).map(
            (fullstr: any, key) =>
              menus[fullstr].split('.')[0].split('_')[1] == tag && (
                <AsideMenuItem
                  key={key}
                  to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
                  icon='/media/icons/duotune/arrows/arr001.svg'
                  title={intl.formatMessage({
                    id: 'MENU.' + menus[fullstr].split('.')[1].split('_')[1],
                  })}
                  fontIcon='bi-app-indicator'
                />
              )
          )}
        </AsideMenuItemWithSub>
      ))}

      {/* <AsideMenuItem
              to='/modellist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ModelList' })}
              fontIcon='bi-layers'
          /> 
<AsideMenuItem
              to='/methodlist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MethodList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/rolemethodlist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.RoleMethodList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/rolemodellist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.RoleModelList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/menulist'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MenuList' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/menurole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.MenuRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/useraction'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.UserAction' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/culture'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Culture' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/translation'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Translation' })}
              fontIcon='bi-layers'
          /> */}
      {/* <AsideMenuItem
                to='/applicationuser'
                icon='/media/icons/duotune/arrows/arr001.svg'
                title={intl.formatMessage({ id: 'MENU.ApplicationUser' })}
                fontIcon='bi-layers'
            /> */}
      {/* 
<AsideMenuItem
              to='/applicationrole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationuserrole'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationUserRole' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationuserclaim'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationUserClaim' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/applicationroleclaim'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ApplicationRoleClaim' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/tenant'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Tenant' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/settings'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Settings' })}
              fontIcon='bi-layers'
          />*/}
      {/* <AsideMenuItem
                to='/customer'
                icon='/media/icons/duotune/arrows/arr001.svg'
                title={intl.formatMessage({ id: 'MENU.Customer' })}
                fontIcon='bi-layers'
            /> */}
      {/* <AsideMenuItem
                to='/product'
                icon='/media/icons/duotune/arrows/arr001.svg'
                title={intl.formatMessage({ id: 'MENU.Product' })}
                fontIcon='bi-layers'
            /> */}
      {/* <AsideMenuItem
              to='/customercontact'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.CustomerContact' })}
              fontIcon='bi-layers'
          /> */}
      {/* <AsideMenuItem
                to='/contract'
                icon='/media/icons/duotune/arrows/arr001.svg'
                title={intl.formatMessage({ id: 'MENU.Contract' })}
                fontIcon='bi-layers'
            /> */}
      {/* <AsideMenuItem
                to='/offer'
                icon='/media/icons/duotune/arrows/arr001.svg'
                title={intl.formatMessage({ id: 'MENU.Offer' })}
                fontIcon='bi-layers'
            /> */}
      {/* <AsideMenuItem
              to='/contractfile'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ContractFile' })}
              fontIcon='bi-layers'
          /> */}
      {/* <AsideMenuItem
              to='/contractstatus'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ContractStatus' })}
              fontIcon='bi-layers'
          /> 
<AsideMenuItem
              to='/offerstatus'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.OfferStatus' })}
              fontIcon='bi-layers'
          />*/}
      {/* <AsideMenuItem
              to='/paymentplan'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.PaymentPlan' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/invoice'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.Invoice' })}
              fontIcon='bi-layers'
          /> */}
      {/* <AsideMenuItem
              to='/contractproduct'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.ContractProduct' })}
              fontIcon='bi-layers'
          />
<AsideMenuItem
              to='/offerproduct'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={intl.formatMessage({ id: 'MENU.OfferProduct' })}
              fontIcon='bi-layers'
          /> */}
    </>
  )
}
