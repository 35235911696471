import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import React, {FC, useState, useEffect} from 'react'
import {getExpiredPayments} from '../paymentplan/list/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'
type Props = {
  className: string
}

const ExpiredPayments: React.FC<Props> = ({className}) => {
  const [payments, setPayments] = React.useState([])
  const intl = useIntl()

  useEffect(() => {
    getExpiredPayments().then((res: any) => {
      console.log(res)
      setPayments(res || [])
    })
  }, [])

  //   <i className='fa fa-genderless text-warning fs-1'></i>
  //   <i className='fa fa-genderless text-success fs-1'></i>
  //   <i className='fa fa-genderless text-danger fs-1'></i>
  //   <i className='fa fa-genderless text-primary fs-1'></i>
  return (
    <div
      className={`card ${className}`}
      style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', margin: '10px'}}
    >
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>
            {intl.formatMessage({id: 'ExpiredPayments'})}
          </span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
      </div>

      <div
        className='card-body pt-5'
        style={{height: '350px', paddingBottom: '50px', overflowY: 'scroll'}}
      >
        <div className='timeline-label'>
          {payments &&
            payments?.map((item: any, index: number) => {
              const colors = ['text-warning', 'text-success', 'text-danger', 'text-primary']
              //const colorIndex = index % colors.length // Renk indeksi hesaplanır
              return (
                <div className='timeline-item'>
                  {/* begin::Label */}
                  <div
                    className='timeline-label fw-bold text-gray-800 fs-6'
                    style={{width: '90px'}}
                  >
                    {item.paymentDate && moment(item.paymentDate).format('DD-MM-YYYY')}
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge'>
                    <i className={`fa fa-genderless ${colors[2]} fs-1`}></i>
                  </div>
                  {/* end::Badge */}
                  {/* begin::Content */}
                  <div className='timeline-content d-flex'>
                    <span
                      className='fw-bold text-gray-800 ps-3'
                      style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    >
                      {' '}
                      {item.offer ? item.offer.customer.name : item.contract.customer.name} -
                      {/*item.name && item.name*/}
                    </span>
                    <span
                      className='fw-bold text-gray-800 ps-3'
                      style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    >
                      {item.offer ? item.offer.name : item.contract.name} -
                      {/*item.name && item.name*/}
                    </span>
                    <span
                      className='fw-bold text-gray-800 ps-3'
                      style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    >
                      {item.name && item.name}
                    </span>
                  </div>
                  {/* end::Content */}
                </div>
              )
            })}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ExpiredPayments}
