import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import React, {FC, useState, useEffect} from 'react'
import {getAllNotes, updateCustomerNote, clearCache} from '../customernote/list/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
type Props = {
  className: string
}

const DashboardNotes: React.FC<Props> = ({className}) => {
  const [notes, setNotes] = React.useState([])
  const intl = useIntl()

  useEffect(() => {
    getAllNotes().then((res: any) => {
      console.log(res.data)
      setNotes(res.data || [])
    })
  }, [])

  useEffect(() => {
    clearCache()
  }, [notes])

  const updateNote = (item: any) => {
    console.log(item)
    item.isComplete = !item.isComplete
    let newItem = item
    console.log(newItem)
    updateCustomerNote(newItem).then(() => {
      getAllNotes().then((res: any) => {
        setNotes(res.data || [])
      })
    })
  }

  //   <i className='fa fa-genderless text-warning fs-1'></i>
  //   <i className='fa fa-genderless text-success fs-1'></i>
  //   <i className='fa fa-genderless text-danger fs-1'></i>
  //   <i className='fa fa-genderless text-primary fs-1'></i>
  return (
    <div
      className={`card ${className}`}
      style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', margin: '10px'}}
    >
      <div className='card-header align-items-center border-0 mt-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'Notes'})}</span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
      </div>

      <div
        className='card-body pt-5'
        style={{height: '350px', paddingBottom: '50px', overflowY: 'scroll'}}
      >
        <div>
          {notes &&
            notes?.map((item: any, index: number) => {
              return (
                <div
                  className='timeline-content d-flex m-2'
                  style={{borderBottom: '1px solid #ccc', padding: '10px'}}
                >
                  <div className='row w-100'>
                    <div className='col-1 d-flex align-items-center justify-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen007.svg'
                        className='svg-icon-3 text-primary'
                      />
                    </div>

                    <div className='col-3 d-flex align-items-center justify-center'>
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {' '}
                        {item.customer.name && item.customer.name}
                      </span>
                    </div>

                    <div className='col-6 d-flex align-items-center justify-center'>
                      <span
                        className='fw-bold text-gray-800'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item.name && item.name}
                      </span>
                    </div>
                    <div className='col-2 d-flex align-items-center justify-center'>
                      <span
                        className='fw-bold text-gray-800 '
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}
                      >
                        <button
                          className='btn btn-light me-3'
                          style={{backgroundColor: '#e0e0e0'}}
                          onClick={async () => {
                            Swal.fire({
                              color: '#000000',
                              title: intl.formatMessage({id: 'SWEETALERT.TITLENOTE'}),
                              text: intl.formatMessage({id: 'SWEETALERT.TEXTNOTE'}),
                              icon: 'warning',
                              confirmButtonText: intl.formatMessage({id: 'SWEETALERT.CONFIRM'}),
                              cancelButtonText: intl.formatMessage({id: 'SWEETALERT.CANCEL'}),
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                await updateNote(item)
                                Swal.fire({
                                  title: intl.formatMessage({id: 'SWEETALERT.SUCCESSNOTE'}),
                                  text: intl.formatMessage({id: 'SWEETALERT.CONFIRMEDNOTE'}),
                                  icon: 'success',
                                  timer: 2000,
                                  showConfirmButton: true,
                                })
                              }
                            })
                          }}
                        >
                          Onayla
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export {DashboardNotes}
